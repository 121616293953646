<template>
  <app-header />
  <div>
    <app-sidebar />
    <div class="single-channel-page" id="content-wrapper">
      <Suspense>
        <template #default>
          <app-categorys :id="id" :name_cat="name_cat" />
        </template>
        <template #fallback>
          <loading />
        </template>
      </Suspense>
    </div>
    <app-footer />
  </div>
</template>

<script>
import AppFooter from "../../components/Include/AppFooter.vue";
import AppHeader from "../../components/Include/AppHeader.vue";
import AppSidebar from "../../components/Include/AppSidebar.vue";
import { defineAsyncComponent, Suspense } from "vue";
import Loading from "../../components/Include/Loading.vue";
const AppCategorys = defineAsyncComponent(() =>
  import("../../components/Pages/AppCategorys.vue")
);
export default {
  components: {
    AppHeader,
    AppSidebar,
    AppCategorys,
    AppFooter,
    Loading,
    Suspense,
  },
  name: "Categorys",
  props: {
    id: String,
    name_cat: String,
  },
};
</script>

<style>
</style>